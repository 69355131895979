// components/UserManagement.js
import React, { useState, useEffect, useCallback } from 'react';
import API from '../services/api';
import '../styles/UserManagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faUserPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    role: 'user',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const resetForm = () => {
    setFormData({ username: '', password: '', role: 'user' });
    setEditingUser(null);
  };

  const fetchUsers = useCallback(() => {
    setIsLoading(true);
    setError(null);
    API.get('/users')
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.error("Fehler beim Laden der Benutzer:", err);
        setError('Fehler beim Laden der Benutzer.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddUser = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    API.post('/users', formData)
      .then((res) => {
        // setUsers([...users, res.data]); // Fetch instead to get consistent data
        fetchUsers(); // Fetch the updated list
        resetForm();
      })
      .catch((err) => {
        console.error("Fehler beim Hinzufügen des Benutzers:", err);
        setError(err.response?.data?.message || 'Fehler beim Hinzufügen des Benutzers.');
        alert(err.response?.data?.message || 'Fehler beim Hinzufügen des Benutzers.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    // Pre-fill form, password is intentionally left blank for editing
    setFormData({ username: user.username, password: '', role: user.role });
  };

  const handleUpdateUser = (e) => {
    e.preventDefault();
    if (!editingUser) return;

    setIsLoading(true);
    setError(null);

    // Prepare data: only include password if it's not empty
    const updateData = {
        username: formData.username,
        role: formData.role,
    };
    if (formData.password) {
        updateData.password = formData.password;
    }

    API.put(`/users/${editingUser._id}`, updateData)
      .then(() => {
        fetchUsers(); // Fetch the updated list
        resetForm();
      })
      .catch((err) => {
        console.error("Fehler beim Aktualisieren des Benutzers:", err);
        setError(err.response?.data?.message || 'Fehler beim Aktualisieren des Benutzers.');
        alert(err.response?.data?.message || 'Fehler beim Aktualisieren des Benutzers.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancelEdit = () => {
    resetForm();
  };

  const handleDeleteUser = (id) => {
    if (window.confirm('Benutzer wirklich löschen?')) {
      setIsLoading(true);
      setError(null);
      API.delete(`/users/${id}`)
        .then(() => {
          // setUsers(users.filter((user) => user._id !== id)); // Use fetch for consistency
          fetchUsers(); // Fetch the updated list
        })
        .catch((err) => {
          console.error("Fehler beim Löschen des Benutzers:", err);
          setError(err.response?.data?.message || 'Fehler beim Löschen des Benutzers.');
          alert(err.response?.data?.message || 'Fehler beim Löschen des Benutzers.');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <main className="user-management">
      <h2>Benutzerverwaltung</h2>

      {/* Role Rights Section */}
      <section className="role-rights">
        <h3>Rechte der Benutzerrollen</h3>
        <div className="role-rights-grid">
          <div className="role-card admin">
            <h4>Admin</h4>
            <ul>
              <li>Kann Benutzer erstellen (einschließlich Gast-Benutzer)</li>
              <li>Hat Zugriff auf den Adminbereich</li>
              <li>Kann Steine erstellen, bearbeiten und löschen</li>
            </ul>
          </div>
          <div className="role-card user">
            <h4>User</h4>
            <ul>
              <li>Kann sich anmelden und die Liste der Steine ansehen</li>
              <li>Kann Steindetails ansehen, aber keine Änderungen vornehmen</li>
            </ul>
          </div>
          <div className="role-card guest">
            <h4>Gast</h4>
            <ul>
              <li>Kann sich anmelden und die Liste der Steine ansehen</li>
              <li>Kann keine Steindetails einsehen (dies wird in App.js erzwungen)</li>
              {/* <li>Die Idee ist, dass der Admin einstellt, welche Steine und welche Details der Gast sehen kann. (Diese Feature ist jedoch noch nicht implementiert.)</li> */}
            </ul>
          </div>
        </div>
      </section>


      {/* User Form Section */}
      <section className="user-form-section">
        <h3>{editingUser ? 'Benutzer bearbeiten' : 'Neuen Benutzer hinzufügen'}</h3>
        <form onSubmit={editingUser ? handleUpdateUser : handleAddUser} className="user-form">
          <div className="form-group">
            <label htmlFor="username">Benutzername:</label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Benutzername"
              value={formData.username}
              onChange={handleChange}
              required
              disabled={isLoading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">{editingUser ? 'Neues Passwort (optional):' : 'Passwort:'}</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder={editingUser ? 'Leer lassen, um nicht zu ändern' : 'Passwort'}
              value={formData.password}
              onChange={handleChange}
              required={!editingUser} // Required only when adding
              disabled={isLoading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="role">Rolle:</label>
            <select id="role" name="role" value={formData.role} onChange={handleChange} disabled={isLoading}>
              <option value="user">Benutzer</option>
              <option value="admin">Admin</option>
              <option value="guest">Gast</option>
            </select>
          </div>
          {error && <p className="error-message">{error}</p>}
          <div className="button-group">
            {editingUser ? (
              <>
                <button type="submit" className="btn btn-submit" disabled={isLoading}>
                  <FontAwesomeIcon icon={faSave} /> {isLoading ? 'Speichern...' : 'Aktualisieren'}
                </button>
                <button type="button" className="btn btn-cancel" onClick={handleCancelEdit} disabled={isLoading}>
                  <FontAwesomeIcon icon={faTimes} /> Abbrechen
                </button>
              </>
            ) : (
              <button type="submit" className="btn btn-submit" disabled={isLoading}>
                <FontAwesomeIcon icon={faUserPlus} /> {isLoading ? 'Hinzufügen...' : 'Benutzer hinzufügen'}
              </button>
            )}
          </div>
        </form>
      </section>

      {/* User List Section */}
      <section className="user-list-section">
        <h3>Bestehende Benutzer</h3>
        {isLoading && users.length === 0 && <p>Lade Benutzer...</p>}
        {!isLoading && users.length === 0 && <p>Keine Benutzer gefunden.</p>}
        {users.length > 0 && (
          <table className="user-table">
            <thead>
              <tr>
                <th>Benutzername</th>
                <th>Rolle</th>
                <th>Aktionen</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user.username}</td>
                  <td>{user.role}</td>
                  <td>
                    <button
                      onClick={() => handleEditUser(user)}
                      className="btn-icon"
                      title="Benutzer bearbeiten"
                      disabled={isLoading}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button
                      onClick={() => handleDeleteUser(user._id)}
                      className="btn-icon"
                      title="Benutzer löschen"
                      disabled={isLoading}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </section>
    </main>
  );
}

export default UserManagement;