// App.js
import React from 'react'; // Removed useState, useEffect as validation is simplified
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './styles/Global.css';
import './styles/App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import SteinListe from './components/SteinListe';
import SteinDetail from './components/SteinDetail';
import Login from './components/Login';
import UserManagement from './components/UserManagement'; // Import UserManagement for the admin route
// AdminPanel import is removed as it's not used

// Protected Route Component - Simplified (relies more on interceptor for token validity)
const ProtectedRoute = ({ children, requiredRole = null }) => {
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('role');

  if (!token) {
    // If no token, redirect to login immediately
    console.log("ProtectedRoute: No token found, redirecting to login.");
    return <Navigate to="/login" replace />;
  }

  // Check if a specific role is required and if the user has it
  if (requiredRole && userRole !== requiredRole) {
    // If role is required but doesn't match, redirect to home page
    console.log(`ProtectedRoute: Role '${requiredRole}' required, user has '${userRole}'. Redirecting home.`);
    return <Navigate to="/" replace />;
  }

  // If token exists and role matches (or no specific role required), render the children
  return children;
};

function App() {
  // No complex auth state needed here anymore, rely on localStorage presence
  // and the ProtectedRoute component / API interceptor

  return (
    <Router>
      <div className="app-container">
        {/* Pass token presence to Header for conditional rendering */}
        <Header isAuth={!!localStorage.getItem('token')} />
        <main className="content"> {/* Use main tag semantically */}
          <Routes>
            {/* Public Login Route */}
            <Route path="/login" element={<Login />} />

            {/* Protected Routes */}
            {/* Root path (Stone List) - accessible to all logged-in users */}
            <Route path="/" element={
              <ProtectedRoute>
                <SteinListe />
              </ProtectedRoute>
            } />

            {/* Create New Stone - accessible only to admin */}
            {/* Note: SteinDetail component handles the 'new' case internally */}
            <Route path="/steine/new" element={
              <ProtectedRoute requiredRole="admin">
                <SteinDetail />
              </ProtectedRoute>
            } />

            {/* View/Edit Stone Detail - accessible to admin and user (guests redirected by logic within) */}
            {/* Guests attempting access should be handled by SteinDetail's internal logic/redirect */}
            <Route path="/steine/:id" element={
              <ProtectedRoute>
                <SteinDetail />
              </ProtectedRoute>
            } />

            {/* Admin Area (User Management) - accessible only to admin */}
            <Route path="/admin" element={
              <ProtectedRoute requiredRole="admin">
                <UserManagement />
              </ProtectedRoute>
            } />

            {/* Fallback Route: Redirects any unknown path */}
            {/* If logged in, redirect to home, otherwise to login */}
            <Route
                path="*"
                element={
                    localStorage.getItem('token') ? (
                        <Navigate to="/" replace />
                    ) : (
                        <Navigate to="/login" replace />
                    )
                }
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;