// components/Header.js
import React from 'react';
import { Link, NavLink } from 'react-router-dom'; // Use NavLink for active styles
import '../styles/Header.css';

function Header() {
  const token = localStorage.getItem('token');
  const userRole = localStorage.getItem('role') || 'guest'; // Default to guest if no role

  const handleLogout = () => {
    // Clear potentially sensitive scroll/filter state on logout
    sessionStorage.removeItem('scrollPosition');
    sessionStorage.removeItem('restoreScroll');
    // Consider clearing URL params as well, though redirect handles most cases
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    // Redirect to login - using window.location.href forces a full refresh
    // which can be good after logout to clear all state.
    window.location.href = '/login';
  };

  return (
    <header>
      <nav className="navbar">
        <div className="navbar-brand">
           {/* Link the brand logo/name to the homepage */}
          <Link to="/" className="brand-link">
             <h1>Turmalin-Steine</h1>
          </Link>
        </div>
        <div className="navbar-links">
          {token ? (
            <>
              {/* Use NavLink to automatically apply an 'active' class */}
              <NavLink to="/" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"} end>
                  Steine
              </NavLink>
              {userRole === 'admin' && (
                <NavLink to="/admin" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                    Adminbereich
                </NavLink>
              )}
              <button onClick={handleLogout} className="btn-logout">Logout</button>
            </>
          ) : (
            // Only show login link if not logged in
            <NavLink to="/login" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                Login
            </NavLink>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;