// components/SteinDetail.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Navigate, useNavigate, useLocation } from 'react-router-dom';
import API from '../services/api';
import '../styles/SteinDetail.css';
import '../styles/Form.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faEdit, faTrash, faArrowLeft } from '@fortawesome/free-solid-svg-icons'; // Added faArrowLeft

// Initial state for a new Stein
const initialSteinState = {
  name: '', farbe: '', durchmesser: null, laenge: null, breite: null,
  gewicht: null, preisProGramm: null, istSerie: false, serienBezeichnung: '',
  kategorie: '', status: 'potenzieller Kauf', kaufPreis: null, kaufDatum: '',
  kaufOrt: '', verkaeufer: '', verkaufPreis: null, verkaufDatum: '',
  verkaufOrt: '', kaeufer: '', notizen: '', bildPfad: '', bildDatei: null,
};


function SteinDetail() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialEditMode = queryParams.get('edit') === 'true' || id === 'new'; // Start in edit mode if 'edit=true' or new

  const [stein, setStein] = useState(id === 'new' ? initialSteinState : null);
  const [isEditing, setIsEditing] = useState(initialEditMode);
  const [isLoading, setIsLoading] = useState(id !== 'new'); // Start loading if fetching existing
  const [error, setError] = useState(null);
  const userRole = localStorage.getItem('role') || 'guest';
  const [serienBezeichnungOptions, setSerienBezeichnungOptions] = useState([]);
  const [showNewSerienBezeichnung, setShowNewSerienBezeichnung] = useState(false);


  // Check permissions early
  useEffect(() => {
    if (userRole === 'guest') {
      // Guests are redirected in App.js routing, but double-check
      console.warn("Guest attempting to access SteinDetail.");
      navigate('/');
    } else if (id === 'new' && userRole !== 'admin') {
      alert('Sie haben keine Berechtigung, einen neuen Stein zu erstellen.');
      navigate('/');
    }
  }, [userRole, id, navigate]);

  // Fetch existing Stein data
  const fetchStein = useCallback((forceRefetch = false) => {
    if ((id === 'new' && !forceRefetch) || userRole === 'guest') return; // Don't fetch if 'new' unless forced, or guest

    const targetId = forceRefetch ? (stein?._id || id) : id; // Use existing ID if force refetching after save
    if (!targetId || targetId === 'new') return; // Ensure we have a valid ID


    setIsLoading(true);
    setError(null);
    API.get(`/steine/${targetId}`)
      .then((res) => {
        // Prepare data for the state, especially dates
        const fetchedData = {
            ...initialSteinState, // Start with defaults
            ...res.data,         // Override with fetched data
            // Ensure dates are in YYYY-MM-DD format for input[type=date]
            kaufDatum: res.data.kaufDatum ? new Date(res.data.kaufDatum).toISOString().substring(0, 10) : '',
            verkaufDatum: res.data.verkaufDatum ? new Date(res.data.verkaufDatum).toISOString().substring(0, 10) : '',
             // Handle nulls explicitly for controlled inputs if needed, but API should return nulls correctly
             durchmesser: res.data.durchmesser ?? '',
             laenge: res.data.laenge ?? '',
             breite: res.data.breite ?? '',
             gewicht: res.data.gewicht ?? '',
             preisProGramm: res.data.preisProGramm ?? '',
             kaufPreis: res.data.kaufPreis ?? '',
             verkaufPreis: res.data.verkaufPreis ?? '',
             serienBezeichnung: res.data.serienBezeichnung ?? '',
             kategorie: res.data.kategorie ?? '',
             kaufOrt: res.data.kaufOrt ?? '',
             verkaeufer: res.data.verkaeufer ?? '',
             verkaufOrt: res.data.verkaufOrt ?? '',
             kaeufer: res.data.kaeufer ?? '',
             notizen: res.data.notizen ?? '',
             farbe: res.data.farbe ?? '',
        };
         // Ensure boolean is correctly set
         fetchedData.istSerie = Boolean(fetchedData.istSerie);

        setStein(fetchedData);
        // If we just saved, exit edit mode
        if (forceRefetch) {
            setIsEditing(false);
            // Optionally remove edit param from URL
             navigate(`/steine/${targetId}`, { replace: true });
        }
      })
      .catch((err) => {
        console.error("Fehler beim Laden des Steins:", err);
        setError(`Stein mit ID ${targetId} konnte nicht geladen werden.`);
        // Optional: Navigate back or show persistent error
         if (!forceRefetch) navigate('/'); // Only navigate back on initial load error
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, userRole, navigate, stein?._id]); // Add stein._id dependency for forceRefetch case


  // Fetch Serienbezeichnungen
  const fetchSerienBezeichnungen = useCallback(() => {
    API.get('/steine/serienBezeichnungen')
      .then((res) => setSerienBezeichnungOptions(res.data.filter(Boolean).sort()))
      .catch((err) => console.error("Fehler beim Laden der Serienbezeichnungen:", err));
  }, []);


  useEffect(() => {
      fetchStein(); // Fetch stein data on mount/id change
      fetchSerienBezeichnungen(); // Fetch dropdown options
  }, [id, fetchStein, fetchSerienBezeichnungen]); // Depend on id and the fetch functions


   // --- Form Handling ---
   const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;

    setStein((prevState) => {
        let newValue;
        if (type === 'checkbox') {
            newValue = checked;
        } else if (type === 'file') {
            // Handle file input separately
             return { ...prevState, bildDatei: files && files.length > 0 ? files[0] : null };
        } else if (type === 'number') {
            // Allow empty string for temporary state, handle conversion on save
            newValue = value;
        } else if (type === 'date') {
            // Ensure date value is correctly handled (empty or YYYY-MM-DD)
            newValue = value || '';
        }
        else {
            newValue = value;
        }

        const newState = { ...prevState, [name]: newValue };

         // Handle dependent logic for serienBezeichnung visibility
         if (name === 'istSerie') {
            if (!checked) {
                newState.serienBezeichnung = ''; // Clear serienBezeichnung if not a series
                setShowNewSerienBezeichnung(false); // Hide new input field
            }
        }
         // Special handling when 'Add New' option is selected for Serienbezeichnung
         if (name === 'serienBezeichnung' && value === 'neu') {
            // Keep 'neu' in the select visually, but clear the actual value to be saved
            // The actual input for the new name will update stein.serienBezeichnung directly
            newState.serienBezeichnung = ''; // Clear the field that will be saved
        }

        return newState;
    });

     // Show/hide the input for new series name based on selection
     if (name === 'serienBezeichnung') {
        setShowNewSerienBezeichnung(value === 'neu');
    }
};


const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setStein((prevState) => ({
        ...prevState,
        bildDatei: e.target.files[0],
      }));
    } else {
       // Handle case where file selection is cancelled
        setStein((prevState) => ({
            ...prevState,
            bildDatei: null,
        }));
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    setError(null); // Clear previous errors

    if (!stein.name || stein.name.trim() === '') {
      const msg = 'Der Name ist ein Pflichtfeld.';
      setError(msg);
      alert(msg);
      return;
    }

    // Prevent saving if trying to save 'neu' as Serienbezeichnung without entering a value
    if (stein.istSerie && showNewSerienBezeichnung && (!stein.serienBezeichnung || stein.serienBezeichnung.trim() === '')) {
        const msg = 'Bitte geben Sie die neue Serienbezeichnung ein oder wählen Sie eine bestehende aus.';
        setError(msg);
        alert(msg);
        return;
    }


    setIsLoading(true);
    const formData = new FormData();

    // Prepare data for submission - create a copy to modify
    const dataToSend = { ...stein };

    // Clean and format data before appending
    Object.keys(dataToSend).forEach((key) => {
      // Skip internal state like bildDatei or computed values
      if (key === 'bildDatei' || key === '_id' || key === '__v' || key === 'bildPfad') return;

      let value = dataToSend[key];

      // Convert numeric fields: empty string/null/undefined -> null, otherwise parse float
      if (['durchmesser', 'laenge', 'breite', 'gewicht', 'preisProGramm', 'kaufPreis', 'verkaufPreis'].includes(key)) {
        value = (value === '' || value === null || value === undefined) ? null : parseFloat(value);
        if (isNaN(value)) value = null; // Ensure invalid numbers become null
      }
      // Convert date fields: empty/invalid -> null, otherwise ensure ISO format
      else if (['kaufDatum', 'verkaufDatum'].includes(key)) {
         try {
            value = value ? new Date(value).toISOString() : null;
            // Check if the date conversion resulted in an invalid date
            if (value && isNaN(new Date(value).getTime())) {
                value = null;
            }
         } catch (dateError) {
            value = null; // Set to null if date conversion fails
         }
      }
      // Ensure boolean is correctly formatted
      else if (key === 'istSerie') {
        value = Boolean(value);
      }
      // Handle empty strings for other text fields -> convert to null for DB consistency
      else if (typeof value === 'string' && value.trim() === '') {
          value = null;
      }

      // Append valid values to FormData
      // FormData handles null correctly (often omits the field or sends 'null' string depending on server)
      // Check server implementation for how nulls are handled. Sending an empty string might be preferred sometimes.
      // If server expects nulls for empty fields:
       if (value !== null && value !== undefined) {
             formData.append(key, value);
        }
       // If server expects empty strings instead of null for optional text fields:
       /*
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        } else if (['farbe', 'serienBezeichnung', ...otherOptionalTextFields].includes(key)) {
             formData.append(key, ''); // Send empty string for specific optional fields
        }
       */
    });


    // Append the image file if it exists
    if (stein.bildDatei) {
      formData.append('bild', stein.bildDatei);
    }

    // Determine API call type
    const apiCall = id === 'new'
      ? API.post('/steine', formData)
      : API.put(`/steine/${id}`, formData);

    apiCall
      .then((res) => {
        // Success: If new, navigate to the created stein's detail page
        if (id === 'new') {
          alert('Stein erfolgreich erstellt!');
          navigate(`/steine/${res.data._id}`, { replace: true }); // Navigate to new ID, replace history entry
          // State update happens via useEffect on ID change
        } else {
          alert('Stein erfolgreich aktualisiert!');
          // Refetch the updated data to show changes and exit edit mode
          fetchStein(true); // Pass true to force refetch and exit edit mode
        }
      })
      .catch((err) => {
        console.error('Fehler beim Speichern des Steins:', err.response?.data || err.message);
        const errorMsg = err.response?.data?.message || err.response?.data || `Fehler beim Speichern: ${err.message}`;
        setError(errorMsg);
        alert(`Fehler beim Speichern: ${errorMsg}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

    // --- Other Actions ---

    const handleDelete = () => {
        if (id === 'new') return; // Cannot delete unsaved stein

        if (window.confirm('Sind Sie sicher, dass Sie diesen Stein unwiderruflich löschen möchten?')) {
        setIsLoading(true);
        setError(null);
        API.delete(`/steine/${id}`)
            .then(() => {
            alert('Stein erfolgreich gelöscht.');
            navigate('/'); // Navigate back to the list after deletion
            })
            .catch((err) => {
            console.error('Fehler beim Löschen des Steins:', err);
            const errorMsg = err.response?.data?.message || 'Fehler beim Löschen des Steins.';
            setError(errorMsg)
            alert(errorMsg);
            setIsLoading(false);
            });
        // No finally setIsLoading(false) needed here because we navigate away on success.
        }
    };

    const handleEdit = () => {
        if (userRole === 'admin') {
            setIsEditing(true);
            // Add ?edit=true to URL for clarity and potential refresh handling
             navigate(`?edit=true`, { replace: true, state: location.state });
        } else {
        alert('Sie haben keine Berechtigung zum Bearbeiten.');
        }
    };

    const handleCancelEdit = () => {
        setError(null); // Clear any validation errors
        if (id === 'new') {
             // If cancelling creation of a new stein, go back to list
             navigate('/');
        } else {
            // If cancelling edit of existing stein, refetch original data and exit edit mode
            fetchStein(); // Refetch original data
            setIsEditing(false);
             // Remove ?edit=true from URL
             navigate(`/steine/${id}`, { replace: true });
        }
         setShowNewSerienBezeichnung(false); // Reset new series input visibility
    };

    // Navigate back to the list, preserving scroll state via SteinListe logic
    const handleBack = () => {
        // SteinListe component will handle restoring scroll position based on sessionStorage
        navigate('/');
    };


    // --- Render Logic ---

    // Early exit for permission issues handled by useEffect
    if ((userRole === 'guest') || (id === 'new' && userRole !== 'admin')) {
        // Although useEffect redirects, return null to prevent rendering attempts
        return null;
    }


    if (isLoading && !stein) {
        return <main><div className="loading-message">Laden...</div></main>;
    }

    if (error && !isEditing) {
        // Show error prominently if not in editing mode
        return (
             <main>
                 <div className="error-message">{error}</div>
                 <button onClick={handleBack} className="btn btn-back">
                    <FontAwesomeIcon icon={faArrowLeft} /> Zurück zur Liste
                 </button>
             </main>
        );
    }

    if (!stein) {
        // Should not happen if loading/error handled, but as a fallback
        return <main><div className="error-message">Stein nicht gefunden.</div></main>;
    }


    // Check if the user has editing rights
    const canEdit = userRole === 'admin';


    return (
        <main>
        <div className="stein-detail">
            {isEditing ? (
            // --- EDITING MODE ---
            <>
                <h2>{id === 'new' ? 'Neuen Stein erstellen' : 'Stein bearbeiten'}</h2>
                {/* Show general errors during edit */}
                {error && <p className="error-message">{error}</p>}
                <form className="stein-form" onSubmit={handleSave}>
                    {/* Form fields */}
                    <div className="form-grid"> {/* Use grid for better layout */}

                        {/* Basic Info */}
                        <fieldset className="form-section">
                            <legend>Basisinformationen</legend>
                            <div className="form-group">
                                <label htmlFor="name">Name<span className="required">*</span>:</label>
                                <input type="text" id="name" name="name" value={stein.name || ''} onChange={handleChange} required disabled={isLoading} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="kategorie">Kategorie:</label>
                                <input type="text" id="kategorie" name="kategorie" value={stein.kategorie || ''} onChange={handleChange} disabled={isLoading} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="farbe">Farbe:</label>
                                <input type="text" id="farbe" name="farbe" value={stein.farbe || ''} onChange={handleChange} disabled={isLoading} />
                            </div>
                             <div className="form-group">
                                <label htmlFor="status">Status:</label>
                                <select id="status" name="status" value={stein.status || ''} onChange={handleChange} disabled={isLoading}>
                                    <option value="potenzieller Kauf">Potenzieller Kauf</option>
                                    <option value="gekauft">Gekauft</option>
                                    <option value="verkauft">Verkauft</option>
                                    <option value="noch keinen Kontakt">Noch keinen Kontakt</option>
                                </select>
                            </div>
                        </fieldset>

                         {/* Dimensions & Weight */}
                         <fieldset className="form-section">
                             <legend>Maße & Gewicht</legend>
                             <div className="form-group">
                                <label htmlFor="durchmesser">Durchmesser (mm):</label>
                                <input type="number" step="0.01" id="durchmesser" name="durchmesser" value={stein.durchmesser ?? ''} onChange={handleChange} disabled={isLoading}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="laenge">Länge (mm):</label>
                                <input type="number" step="0.01" id="laenge" name="laenge" value={stein.laenge ?? ''} onChange={handleChange} disabled={isLoading}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="breite">Breite (mm):</label>
                                <input type="number" step="0.01" id="breite" name="breite" value={stein.breite ?? ''} onChange={handleChange} disabled={isLoading}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="gewicht">Gewicht (g):</label>
                                <input type="number" step="0.01" id="gewicht" name="gewicht" value={stein.gewicht ?? ''} onChange={handleChange} disabled={isLoading}/>
                            </div>
                             <div className="form-group">
                                <label htmlFor="preisProGramm">Preis pro Gramm (€):</label>
                                <input type="number" step="0.01" id="preisProGramm" name="preisProGramm" value={stein.preisProGramm ?? ''} onChange={handleChange} disabled={isLoading} />
                            </div>
                         </fieldset>


                        {/* Series Info */}
                        <fieldset className="form-section">
                            <legend>Serie</legend>
                            <div className="form-group checkbox-group">
                                <label htmlFor="istSerie">
                                <input type="checkbox" id="istSerie" name="istSerie" checked={stein.istSerie || false} onChange={handleChange} disabled={isLoading}/>
                                Teil einer Serie
                                </label>
                            </div>
                            {/* Conditional rendering based on checkbox state */}
                            {stein.istSerie && (
                                <div className="form-group">
                                <label htmlFor="serienBezeichnung">Serienbezeichnung:</label>
                                <select id="serienBezeichnung" name="serienBezeichnung"
                                        value={showNewSerienBezeichnung ? 'neu' : (stein.serienBezeichnung || '')} // Show 'neu' if adding new
                                        onChange={handleChange}
                                        disabled={isLoading}>
                                    <option value="" disabled={!showNewSerienBezeichnung}>Bitte wählen oder neu hinzufügen</option>
                                    {serienBezeichnungOptions.map((serien) => (
                                    <option key={serien} value={serien}>{serien}</option>
                                    ))}
                                    <option value="neu" className="new-serien-option">-- Neue hinzufügen --</option>
                                </select>
                                </div>
                            )}
                            {stein.istSerie && showNewSerienBezeichnung && (
                                <div className="form-group">
                                <label htmlFor="neueSerienBezeichnungInput">Neue Serienbez.:</label>
                                <input
                                    type="text"
                                    id="neueSerienBezeichnungInput" // Different ID for the input
                                    name="serienBezeichnung" // Update the correct state field
                                    value={stein.serienBezeichnung || ''} // Bind to the field that gets saved
                                    onChange={handleChange}
                                    placeholder="Name der neuen Serie"
                                    disabled={isLoading}
                                    required // Make required if shown
                                />
                                </div>
                            )}
                        </fieldset>

                        {/* Purchase Details */}
                        <fieldset className="form-section details-fieldset">
                            <legend>Kaufdetails</legend>
                            <div className="form-group">
                                <label htmlFor="kaufPreis">Kaufpreis (€):</label>
                                <input type="number" step="0.01" id="kaufPreis" name="kaufPreis" value={stein.kaufPreis ?? ''} onChange={handleChange} disabled={isLoading}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="kaufDatum">Kaufdatum:</label>
                                <input type="date" id="kaufDatum" name="kaufDatum" value={stein.kaufDatum || ''} onChange={handleChange} disabled={isLoading}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="kaufOrt">Kaufort:</label>
                                <input type="text" id="kaufOrt" name="kaufOrt" value={stein.kaufOrt || ''} onChange={handleChange} disabled={isLoading}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="verkaeufer">Verkäufer:</label>
                                <input type="text" id="verkaeufer" name="verkaeufer" value={stein.verkaeufer || ''} onChange={handleChange} disabled={isLoading}/>
                            </div>
                        </fieldset>

                        {/* Sale Details */}
                        <fieldset className="form-section details-fieldset">
                            <legend>Verkaufsdetails</legend>
                            <div className="form-group">
                                <label htmlFor="verkaufPreis">Verkaufspreis (€):</label>
                                <input type="number" step="0.01" id="verkaufPreis" name="verkaufPreis" value={stein.verkaufPreis ?? ''} onChange={handleChange} disabled={isLoading}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="verkaufDatum">Verkaufsdatum:</label>
                                <input type="date" id="verkaufDatum" name="verkaufDatum" value={stein.verkaufDatum || ''} onChange={handleChange} disabled={isLoading}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="verkaufOrt">Verkaufsort:</label>
                                <input type="text" id="verkaufOrt" name="verkaufOrt" value={stein.verkaufOrt || ''} onChange={handleChange} disabled={isLoading}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="kaeufer">Käufer:</label>
                                <input type="text" id="kaeufer" name="kaeufer" value={stein.kaeufer || ''} onChange={handleChange} disabled={isLoading}/>
                            </div>
                        </fieldset>

                         {/* Notes and Image */}
                         <fieldset className="form-section form-section-full-width">
                             <legend>Notizen & Bild</legend>
                             <div className="form-group">
                                <label htmlFor="notizen">Notizen:</label>
                                <textarea id="notizen" name="notizen" value={stein.notizen || ''} onChange={handleChange} rows="5" disabled={isLoading}></textarea>
                            </div>
                            <div className="form-group">
                                <label htmlFor="bild">Bild {stein.bildPfad ? 'ersetzen' : 'hochladen'}:</label>
                                <input type="file" id="bild" name="bild" accept="image/*" capture="environment" onChange={handleImageChange} disabled={isLoading}/>
                                {/* Show current image thumbnail if available */}
                                {stein.bildPfad && !stein.bildDatei && (
                                     <img src={`${process.env.REACT_APP_BASE_URL}/${stein.bildPfad}?t=${new Date().getTime()}`} alt="Aktuelles Bild" className="form-image-thumbnail"/>
                                )}
                                {/* Show preview of newly selected image */}
                                {stein.bildDatei && (
                                    <img src={URL.createObjectURL(stein.bildDatei)} alt="Vorschau" className="form-image-thumbnail"/>
                                )}
                            </div>
                         </fieldset>

                    </div> {/* End of form-grid */}

                    {/* Action Buttons */}
                    <div className="button-group form-actions">
                        <button type="submit" className="btn btn-submit" disabled={isLoading}>
                        <FontAwesomeIcon icon={faSave} /> {isLoading ? 'Speichern...' : 'Speichern'}
                        </button>
                        <button type="button" className="btn btn-cancel" onClick={handleCancelEdit} disabled={isLoading}>
                        <FontAwesomeIcon icon={faTimes} /> Abbrechen
                        </button>
                         {/* Delete button only shown when editing existing */}
                         {id !== 'new' && canEdit && (
                            <button type="button" onClick={handleDelete} className="btn btn-delete" disabled={isLoading}>
                                <FontAwesomeIcon icon={faTrash} /> Löschen
                            </button>
                         )}
                    </div>
                </form>
            </>
            ) : (
            // --- VIEW MODE ---
            <>
                <h2>{stein.name || 'Unbenannter Stein'}</h2>
                {stein.bildPfad ? (
                <img
                    src={`${process.env.REACT_APP_BASE_URL}/${stein.bildPfad}?t=${new Date().getTime()}`} // Cache buster
                    alt={stein.name || 'Steinbild'}
                    className="detail-image"
                    onError={(e) => {
                        console.error('Fehler beim Laden des Bildes:', e.target.src);
                        e.target.style.display = 'none'; // Hide broken image
                         // Optionally show a placeholder
                        const placeholder = document.createElement('div');
                        placeholder.textContent = 'Bild nicht verfügbar';
                        placeholder.className = 'image-placeholder-detail';
                        e.target.parentNode.insertBefore(placeholder, e.target.nextSibling);
                    }}
                />
                ) : (
                    <div className="image-placeholder-detail">Kein Bild vorhanden</div>
                )}

                <div className="stein-info">
                {/* Use a helper function or component for displaying fields */}
                {renderDetailField("Kategorie", stein.kategorie)}
                {renderDetailField("Farbe", stein.farbe)}
                {renderDetailField("Status", stein.status)}
                {renderDetailField("Durchmesser (mm)", stein.durchmesser)}
                {renderDetailField("Länge (mm)", stein.laenge)}
                {renderDetailField("Breite (mm)", stein.breite)}
                {renderDetailField("Gewicht (g)", stein.gewicht)}
                {renderDetailField("Preis pro Gramm (€)", stein.preisProGramm)}
                {renderDetailField("Teil einer Serie", stein.istSerie ? 'Ja' : 'Nein')}
                {stein.istSerie && renderDetailField("Serienbezeichnung", stein.serienBezeichnung)}

                {/* Kaufdetails Section */}
                {(stein.kaufPreis || stein.kaufDatum || stein.kaufOrt || stein.verkaeufer) && (
                    <div className="info-section">
                        <h3>Kaufdetails</h3>
                        {renderDetailField("Kaufpreis (€)", stein.kaufPreis)}
                        {renderDetailField("Kaufdatum", stein.kaufDatum ? new Date(stein.kaufDatum).toLocaleDateString('de-DE') : null)}
                        {renderDetailField("Kaufort", stein.kaufOrt)}
                        {renderDetailField("Verkäufer", stein.verkaeufer)}
                    </div>
                )}

                {/* Verkaufsdetails Section */}
                {(stein.verkaufPreis || stein.verkaufDatum || stein.verkaufOrt || stein.kaeufer) && (
                    <div className="info-section">
                        <h3>Verkaufsdetails</h3>
                        {renderDetailField("Verkaufspreis (€)", stein.verkaufPreis)}
                        {renderDetailField("Verkaufsdatum", stein.verkaufDatum ? new Date(stein.verkaufDatum).toLocaleDateString('de-DE') : null)}
                        {renderDetailField("Verkaufsort", stein.verkaufOrt)}
                        {renderDetailField("Käufer", stein.kaeufer)}
                    </div>
                )}

                {/* Notizen Section */}
                {stein.notizen && (
                    <div className="info-section">
                    <h3>Notizen</h3>
                    <p className="notizen-text">{stein.notizen}</p>
                    </div>
                )}
                </div>

                {/* Action Buttons for View Mode */}
                <div className="button-group view-actions">
                    <button onClick={handleBack} className="btn btn-back">
                        <FontAwesomeIcon icon={faArrowLeft} /> Zurück zur Liste
                    </button>
                    {canEdit && (
                        <>
                            <button onClick={handleEdit} className="btn btn-edit">
                            <FontAwesomeIcon icon={faEdit} /> Bearbeiten
                            </button>
                            <button onClick={handleDelete} className="btn btn-delete">
                            <FontAwesomeIcon icon={faTrash} /> Löschen
                            </button>
                        </>
                    )}
                </div>
            </>
            )}
        </div>
        </main>
    );
}

// Helper function to render detail fields conditionally
function renderDetailField(label, value) {
  // Check for null, undefined, and empty string
  if (value === null || value === undefined || value === '') {
    return null; // Don't render if value is absent
  }
  return (
    <p>
      <strong>{label}:</strong> {value}
    </p>
  );
}

export default SteinDetail;