// components/Modal.js
import React, { useEffect } from 'react';
import '../styles/Modal.css';

function Modal({ onClose, children }) {
  // Close modal on Escape key press
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    // Cleanup listener on unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  // Prevent background scroll when modal is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    // Cleanup: restore scroll on unmount
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);


  return (
    // Use role="dialog" and aria-modal="true" for accessibility
    <div className="modal-overlay" onClick={onClose} role="dialog" aria-modal="true">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* Improved close button for accessibility */}
        <button
          className="modal-close"
          onClick={onClose}
          aria-label="Dialog schließen" // Add accessibility label
        >
          ×
        </button>
        {children}
      </div>
    </div>
  );
}

export default Modal;