// services/api.js
import axios from 'axios';

// Ensure REACT_APP_API_URL is defined in your .env file
const baseURL = process.env.REACT_APP_API_URL || '/api';

if (!baseURL) {
  console.error("REACT_APP_API_URL is not defined. Please check your .env configuration.");
}

const API = axios.create({
  baseURL: baseURL, // Fallback to relative path if env var is missing
  headers: {
    'Content-Type': 'application/json', // Default content type for JSON requests
  }
});

// Interceptor to add the Authorization token to requests
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      // Das Backend erwartet das Token ohne "Bearer " Präfix
      // Siehe backend/middleware/auth.js: const token = req.header('Authorization');
      config.headers['Authorization'] = token;
      
      // Debugging: Zeige den Header im Konsolenfenster (nur die ersten 10 Zeichen)
      if (token.length > 10) {
        console.log('Authorization-Header gesetzt:', token.substring(0, 10) + '...');
      } else {
        console.log('Authorization-Header gesetzt:', token);  
      }
    } else {
      console.log('Kein Token gefunden im localStorage');
    }

    // Handle FormData content type specifically
    if (config.data instanceof FormData) {
      // Axios usually sets this automatically, but explicitly removing ensures correctness
      // Let the browser set the correct Content-Type with boundary for FormData
      delete config.headers['Content-Type'];
    }

    return config;
  },
  (error) => {
    // Handle request errors (e.g., network issues)
    console.error("API Request Error:", error);
    return Promise.reject(error);
  }
);

// Interceptor to handle common responses, like 401 Unauthorized
API.interceptors.response.use(
  (response) => {
    // Any status code within the range of 2xx causes this function to trigger
    return response;
  },
  (error) => {
    // Any status codes outside the range of 2xx causes this function to trigger
    const { status, data } = error.response || {};

    if (status === 401 || (status === 400 && data === 'Ungültiges Token')) {
      // Handle Unauthorized: Token expired or invalid
      console.error(`API Response Error: ${status === 401 ? 'Unauthorized (401)' : 'Invalid Token (400)'}`);
      // Clear local storage and redirect to login
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      // Use window.location.href for a hard redirect to clear state
      if (window.location.pathname !== '/login') { // Avoid redirect loop if already on login page
        window.location.href = '/login?sessionExpired=true'; // Add query param for context
      }
    } else if (status === 403) {
      console.error("API Response Error: Forbidden (403) - User does not have permission.");
      // Optionally show a generic permission error message to the user
      // alert("You do not have permission to perform this action.");
    } else {
      // Handle other errors (4xx, 5xx)
      console.error(`API Response Error: ${status || 'Unknown'}`, error.response?.data || error.message);
    }

    return Promise.reject(error); // Pass the error along for component-level handling
  }
);

export default API;