// components/Footer.js
import React from 'react';
import '../styles/Footer.css'; // Ensure the CSS file exists and is styled

function Footer() {
    const currentYear = new Date().getFullYear(); // Get current year dynamically
    return (
        <footer>
            <p>© {currentYear} Turmalin-Steine</p>
            {/* You could add more footer links or information here */}
        </footer>
    );
}

export default Footer;