// components/SteinListe.js
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Link, useNavigate, useSearchParams as useReactRouterSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import * as R from 'ramda';
import API from '../services/api';
import Modal from './Modal';
import '../styles/SteinListe.css';
import '../styles/SearchForm.css';
import '../styles/Sort.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faSortUp, faSortDown, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

// Helper function to get search params as an object
const getParamsObject = (searchParams) => Object.fromEntries(searchParams.entries());

// Define default values for parameters
const defaultParams = {
    search: '', farbe: '', kategorie: '', serienBezeichnung: '', istSerie: 'false',
    status: '', gewichtMin: '', gewichtMax: '', preisProGrammMin: '', preisProGrammMax: '',
    durchmesserMin: '', durchmesserMax: '', kaufPreisMin: '', kaufPreisMax: '',
    kaufDatumVon: '', kaufDatumBis: '', verkaufPreisMin: '', verkaufPreisMax: '',
    verkaufDatumVon: '', verkaufDatumBis: '', laengeMin: '', laengeMax: '', breiteMin: '', breiteMax: '',
    sortBy: 'name', sortOrder: 'asc', page: '1', limit: '0',
};

function SteinListe() {
    // Test Ramda's map function
    console.log("Testing Ramda map:", R.map(x => x * 2, [1, 2, 3])); // Should output [2, 4, 6]

    const [steine, setSteine] = useState([]);
    const [totalSteine, setTotalSteine] = useState(0);
    const userRole = localStorage.getItem('role') || 'guest';
    const [isLoading, setIsLoading] = useState(true); // Start loading initially
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useReactRouterSearchParams();

    // State derived from URL parameters
    const currentParams = useMemo(() => ({
        ...defaultParams,
        ...getParamsObject(searchParams),
    }), [searchParams]);

    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showFilter, setShowFilter] = useState(false);

    // Filter options state
    const [farbenOptions, setFarbenOptions] = useState([]);
    const [kategorieOptions, setKategorieOptions] = useState([]);
    const [serienBezeichnungOptions, setSerienBezeichnungOptions] = useState([]);

    // Ref to track if scroll restoration logic should be active
    const isRestoringScroll = useRef(sessionStorage.getItem('restoreScroll') === 'true');
    // Ref to track previous params to detect actual changes relevant for scrolling top
    const prevParamsRef = useRef(currentParams);

    // --- Fetching Data ---

    const fetchFilterOptions = useCallback(() => {
        API.get('/steine/farben')
            .then((res) => setFarbenOptions(res.data.filter(Boolean).sort()))
            .catch((err) => console.error("Fehler beim Laden der Farben:", err));
        API.get('/steine/kategorien')
            .then((res) => setKategorieOptions(res.data.filter(Boolean).sort()))
            .catch((err) => console.error("Fehler beim Laden der Kategorien:", err));
        API.get('/steine/serienBezeichnungen')
            .then((res) => setSerienBezeichnungOptions(res.data.filter(Boolean).sort()))
            .catch((err) => console.error("Fehler beim Laden der Serienbezeichnungen:", err));
    }, []);

    // Fetch Steine based on currentParams (URL)
    const fetchSteine = useCallback((paramsToUse) => {
        setIsLoading(true); // Set loading true *before* the API call
        setError(null);
        const query = new URLSearchParams(paramsToUse).toString();
        console.log("Fetching steine with query:", query);

        API.get(`/steine/search?${query}`)
            .then((res) => {
                setSteine(res.data.steine || []);
                setTotalSteine(res.data.total || 0);
            })
            .catch((err) => {
                console.error("Fehler beim Laden der Steine:", err);
                if (err.response && (err.response.status === 401 || (err.response.status === 400 && err.response.data === 'Ungültiges Token'))) {
                    setError('Sitzung abgelaufen. Bitte neu anmelden.');
                } else {
                    setError('Fehler beim Laden der Steine. Bitte erneut versuchen.');
                }
                setSteine([]);
                setTotalSteine(0);
            })
            .finally(() => {
                setIsLoading(false);
                console.log("Fetching finished. isLoading:", false);
            });
    }, []); // No external dependencies needed

    // Debounced fetch trigger
    const debouncedFetch = useCallback(debounce((params) => {
        fetchSteine(params);
    }, 350), [fetchSteine]); // fetchSteine is stable due to useCallback

    // Effect to fetch data when URL params change
    useEffect(() => {
        console.log("Params changed, triggering fetch:", currentParams);
        // Check if this is a navigation back scenario
        isRestoringScroll.current = sessionStorage.getItem('restoreScroll') === 'true';
        debouncedFetch(currentParams);
        // Cleanup the debounce timer on unmount or before next run
        return () => {
            debouncedFetch.cancel();
        };
    }, [currentParams, debouncedFetch]); // Depend only on params and the fetch function

    // --- Initial Load ---
    useEffect(() => {
        fetchFilterOptions();
    }, [fetchFilterOptions]); // fetchFilterOptions is stable

    // --- Scroll Restoration Logic ---
    useEffect(() => {
        // Only attempt restore if loading is finished AND the flag was set on mount/param change
        if (!isLoading && isRestoringScroll.current) {
            const position = sessionStorage.getItem('scrollPosition');
            const stonesAvailable = Array.isArray(steine) && steine.length > 0;

            console.log(`Scroll Restore Check: isLoading=${isLoading}, isRestoringFlag=${isRestoringScroll.current}, position=${position}, stonesAvailable=${stonesAvailable}`);

            if (position != null && stonesAvailable) {
                console.log(`Attempting scroll restore to ${position}`);
                // Use rAF for better timing after render and DOM update
                requestAnimationFrame(() => {
                    window.scrollTo({
                        top: parseInt(position, 10),
                        behavior: 'instant', // Use instant to avoid visual jump during restore
                    });
                    console.log("Scroll restore finished. Removing flags.");
                    // Clean up flags *after* successful restore attempt
                    sessionStorage.removeItem('scrollPosition');
                    sessionStorage.removeItem('restoreScroll');
                    isRestoringScroll.current = false; // Update ref state
                });
            } else {
                // If restore was intended but couldn't happen (e.g., no stones, position missing),
                // still clear the flag so subsequent actions scroll to top.
                console.log("Scroll restore conditions not met or failed, removing flags.");
                sessionStorage.removeItem('scrollPosition');
                sessionStorage.removeItem('restoreScroll');
                isRestoringScroll.current = false; // Update ref state
            }
        }
    // This effect should run primarily when loading finishes, or when stones update (to ensure content is there)
    }, [isLoading, steine]);


    // --- Scroll To Top on Filter/Page/Sort Change ---
    useEffect(() => {
        const paramsJustChanged = prevParamsRef.current !== currentParams;

        // Scroll to top if:
        // 1. Parameters relevant to the list view (filters, sort, page) have actually changed.
        // 2. We are *not* in a scroll restoration cycle (checked via the flag in sessionStorage).
        // 3. Loading is complete (to scroll relative to the final section position).
        // OR: Scroll immediately when params change if NOT restoring, to prevent seeing the old list at the top.
        // Let's try scrolling immediately when params change *if not restoring*.

        const shouldRestore = sessionStorage.getItem('restoreScroll') === 'true'; // Check flag directly

        // Compare relevant params, ignore limit if it's the only change? Maybe not necessary.
        const relevantParamsChanged = (
            prevParamsRef.current.page !== currentParams.page ||
            prevParamsRef.current.sortBy !== currentParams.sortBy ||
            prevParamsRef.current.sortOrder !== currentParams.sortOrder ||
            prevParamsRef.current.limit !== currentParams.limit || // Include limit change
            prevParamsRef.current.search !== currentParams.search ||
            prevParamsRef.current.farbe !== currentParams.farbe ||
            prevParamsRef.current.kategorie !== currentParams.kategorie ||
            prevParamsRef.current.serienBezeichnung !== currentParams.serienBezeichnung ||
            prevParamsRef.current.istSerie !== currentParams.istSerie ||
            prevParamsRef.current.status !== currentParams.status ||
            // Add other filters here if needed for completeness
            prevParamsRef.current.gewichtMin !== currentParams.gewichtMin ||
            prevParamsRef.current.gewichtMax !== currentParams.gewichtMax // ... etc.
        );


        if (relevantParamsChanged && !shouldRestore) {
            console.log("Scrolling to top because relevant params changed and no restore flag set.");
            const listElement = document.querySelector('.stones-list-section');
            if (listElement) {
                 // Scroll instantly to avoid jumpiness when content changes
                 window.scrollTo({
                    top: listElement.offsetTop - 80, // Adjust offset slightly if needed (e.g., for fixed headers)
                    behavior: 'instant',
                });
            }
        }

        // Update the ref to the current params *after* the check
        // This ensures the check compares the *new* params against the *previous* ones.
        if (paramsJustChanged) {
             prevParamsRef.current = currentParams;
        }

    // This effect runs whenever currentParams change.
    // We check the restore flag directly within the effect.
    }, [currentParams]); // Only depend on currentParams


    // --- Event Handlers ---

    // Helper to update URL search parameters
    const updateSearchParams = (newValues) => {
        const updated = new URLSearchParams(searchParams);
        let changed = false;
        let filtersChanged = false; // Track if filters specifically changed

        for (const key in newValues) {
            const oldValue = updated.get(key) ?? defaultParams[key]; // Use default if not present
            // Ensure comparison is consistent (e.g., treat null/undefined as empty string for comparison)
            const newValue = String(newValues[key] ?? '');
            const effectiveOldValue = String(oldValue ?? ''); // Treat null/undefined from defaultParams as empty string too

            if (newValue !== effectiveOldValue) {
                 // Determine if it's a filter key vs pagination/sort
                 const isFilterKey = !['page', 'limit', 'sortBy', 'sortOrder'].includes(key);

                // Handle removal vs setting
                if (newValue === '' || newValue === String(defaultParams[key] ?? '')) { // Remove if empty or back to default
                    if (updated.has(key)) {
                        updated.delete(key);
                        changed = true;
                        if(isFilterKey) filtersChanged = true;
                    }
                } else { // Set new value
                    updated.set(key, newValue);
                    changed = true;
                     if(isFilterKey) filtersChanged = true;
                }
            }
        }

        // Reset page to 1 if filters changed, but not if only page/sort/limit changed
        if (changed && filtersChanged && updated.get('page') !== '1') {
            updated.set('page', '1'); // Reset page if filters were modified
             console.log("Resetting page to 1 due to filter change.");
        } else if (changed && !filtersChanged && newValues.hasOwnProperty('limit') && updated.get('page') !== '1') {
             // Also reset page if limit changes
             updated.set('page', '1');
             console.log("Resetting page to 1 due to limit change.");
        }


        if (changed) {
            console.log("Updating search params:", updated.toString());
            // Clear restore flag before navigating internally via setSearchParams
            sessionStorage.removeItem('restoreScroll');
            sessionStorage.removeItem('scrollPosition');
            isRestoringScroll.current = false;
            setSearchParams(updated, { replace: true });
        }
    };

    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;
        updateSearchParams({ [name]: type === 'checkbox' ? String(checked) : value });
    };

    const handleStatusFilterClick = (status) => {
        updateSearchParams({ status: currentParams.status === status ? '' : status });
    };

    const handleReset = () => {
        // Reset only filters, keep limit? Or reset limit too? Let's keep limit. Reset page.
        const resetParams = {};
        for (const key in defaultParams) {
            if (!['limit'].includes(key)) { // Keep current limit if set, otherwise use default
                 resetParams[key] = defaultParams[key];
            }
        }
        resetParams.limit = currentParams.limit || defaultParams.limit; // Ensure limit is preserved or defaulted

         // Use setSearchParams directly to trigger the update flow
        const resetSearchParams = new URLSearchParams();
        for (const key in resetParams) {
            if (resetParams[key] !== defaultParams[key]) { // Only set non-default values
                 resetSearchParams.set(key, resetParams[key]);
            }
        }
         // Ensure essential defaults like page=1 are set if needed
        if (!resetSearchParams.has('page')) resetSearchParams.set('page', '1');
        if (!resetSearchParams.has('limit')) resetSearchParams.set('limit', defaultParams.limit);

        console.log("Resetting filters. New params:", resetSearchParams.toString());
        // Clear restore flag before navigating internally
        sessionStorage.removeItem('restoreScroll');
        sessionStorage.removeItem('scrollPosition');
        isRestoringScroll.current = false;
        setSearchParams(resetSearchParams, { replace: true });
        setShowFilter(false);
    };


    // --- Navigation Handlers with Scroll Saving ---
    const saveScrollAndNavigate = (path) => {
        const scrollPosition = window.pageYOffset;
        sessionStorage.setItem('scrollPosition', scrollPosition.toString());
        sessionStorage.setItem('restoreScroll', 'true'); // Set flag *before* navigation
        console.log(`Scroll pos saved: ${scrollPosition}. Navigating to ${path}. Restore flag set.`);
        navigate(path);
    };

    const handleEdit = (e, id) => {
        e.stopPropagation(); // Prevent card click
        saveScrollAndNavigate(`/steine/${id}?edit=true`);
    };

     const handleCardClick = (event, stein) => {
        // Ensure click is not on interactive elements within the card
        if (event.target.closest('button, a, input, select, textarea, .bearbeiten-button, .loeschen-button, img')) {
            // Allow clicks on image (for modal), edit, delete buttons to proceed
            if (!event.target.closest('img')) { // If not image click, stop propagation here too
                 return;
            }
            // Let image click propagate to its own handler if necessary, but don't navigate card
            if (event.target.closest('button, a, input, select, textarea')) return;
        }
         saveScrollAndNavigate(`/steine/${stein._id}`);
    };

    const handleDelete = (e, id) => {
        e.stopPropagation(); // Prevent card click
        if (window.confirm('Möchten Sie diesen Stein wirklich löschen?')) {
             // No scroll saving needed here, just refetch
             // Clear restore flag in case it was somehow set
            sessionStorage.removeItem('restoreScroll');
            sessionStorage.removeItem('scrollPosition');
            isRestoringScroll.current = false;
            setIsLoading(true); // Show loading during delete/refetch
            API.delete(`/steine/${id}`)
                .then(() => {
                    // Fetch with current params to refresh the list
                    fetchSteine(currentParams);
                })
                .catch((err) => {
                    console.error("Fehler beim Löschen:", err);
                    setError('Fehler beim Löschen des Steins.');
                    setIsLoading(false); // Stop loading on error
                });
        }
    };

    const handleImageClick = (event, stein) => {
        event.stopPropagation(); // Prevent card click navigation
        if (stein.bildPfad) {
            setSelectedImage(`${process.env.REACT_APP_BASE_URL}/${stein.bildPfad}`);
            setShowModal(true);
        } else {
            console.warn("Kein Bildpfad für dieses Bild vorhanden.");
            // Optionally show placeholder modal or do nothing
        }
    };

    // --- Sorting ---
    const handleSortChange = (e) => {
        const newSortBy = e.target.value;
        // Reset sort order to 'asc' if changing sortBy field, unless it was already selected
        const newSortOrder = newSortBy && newSortBy === currentParams.sortBy ? currentParams.sortOrder : 'asc';
        updateSearchParams({ sortBy: newSortBy, sortOrder: newSortOrder });
    };

    const toggleSortOrder = () => {
        // Only toggle if a sortBy field is selected
        if (currentParams.sortBy) {
            updateSearchParams({ sortOrder: currentParams.sortOrder === 'asc' ? 'desc' : 'asc' });
        }
    };

    // --- Pagination ---
    const itemsPerPage = parseInt(currentParams.limit, 10) || 0; // Treat 0 as "show all"
    const currentPage = parseInt(currentParams.page, 10) || 1;
    const gesamtSeiten = itemsPerPage === 0 ? 1 : Math.ceil(totalSteine / itemsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= gesamtSeiten && newPage !== currentPage) {
             updateSearchParams({ page: newPage.toString() });
        }
    };

    const handleItemsPerPageChange = (e) => {
        const newLimit = e.target.value;
        // Reset page to 1 when changing items per page
        updateSearchParams({ limit: newLimit, page: '1' });
    };

    // --- Rendering ---
    const aktuelleSteine = useMemo(() => steine || [], [steine]);

    return (
        <main>
            {/* Search and Filter Section */}
            <section className="search-filter-section">
                {/* Search Bar */}
                 <div className="search-bar">
                     <input
                        type="text" id="search" name="search" placeholder="Suche nach Name oder Notizen..."
                        value={currentParams.search} onChange={handleFilterChange} aria-label="Suche nach Name oder Notizen"
                    />
                    {userRole !== 'guest' && (
                        <button className="toggle-filter-button" onClick={() => setShowFilter(!showFilter)} aria-expanded={showFilter}>
                            {showFilter ? 'Filter ausblenden' : 'Filter anzeigen'}
                        </button>
                    )}
                </div>
                 {/* Status Quick Filters */}
                <div className="status-filter-buttons">
                  {['', 'potenzieller Kauf', 'gekauft', 'verkauft', 'noch keinen Kontakt'].map(status => (
                      <button key={status || 'alle'}
                          className={`status-filter-button ${status.replace(/\s+/g, '-').toLowerCase() || 'all'} ${currentParams.status === status ? 'active' : ''}`}
                          onClick={() => handleStatusFilterClick(status)} > {status || 'Alle'} </button>
                  ))}
                </div>
                 {/* Advanced Filters Form (Conditional) */}
                 {userRole !== 'guest' && showFilter && (
                    <div className="filters">
                        <h2>Filteroptionen</h2>
                        {/* Form structure remains the same */}
                        <form onSubmit={(e) => e.preventDefault()}>
                            {/* General Filters */}
                            <fieldset className="filter-group"> <legend>Allgemein</legend>
                                <div className="filter-item">
                                    <label htmlFor="farbe">Farbe:</label>
                                    <select id="farbe" name="farbe" value={currentParams.farbe} onChange={handleFilterChange}>
                                        <option value="">Alle Farben</option> {farbenOptions.map((f) => <option key={f} value={f}>{f}</option>)}
                                    </select>
                                </div>
                                <div className="filter-item">
                                    <label htmlFor="kategorie">Kategorie:</label>
                                    <select id="kategorie" name="kategorie" value={currentParams.kategorie} onChange={handleFilterChange}>
                                        <option value="">Alle Kategorien</option> {kategorieOptions.map((k) => <option key={k} value={k}>{k}</option>)}
                                    </select>
                                </div>
                                {/* Combined status filter - keep select for consistency if filters shown */}
                                <div className="filter-item">
                                  <label htmlFor="status-select">Status:</label>
                                  <select id="status-select" name="status" value={currentParams.status} onChange={handleFilterChange}>
                                    <option value="">Alle Status</option> <option value="potenzieller Kauf">Potenzieller Kauf</option> <option value="gekauft">Gekauft</option> <option value="verkauft">Verkauft</option> <option value="noch keinen Kontakt">Noch keinen Kontakt</option>
                                  </select>
                                </div>
                                <div className="filter-item checkbox-item">
                                    <label htmlFor="istSerie">
                                        <input type="checkbox" id="istSerie" name="istSerie" checked={currentParams.istSerie === 'true'} onChange={handleFilterChange} /> Teil einer Serie
                                    </label>
                                </div>
                                <div className="filter-item">
                                    <label htmlFor="serienBezeichnung">Serienbezeichnung:</label>
                                    <select id="serienBezeichnung" name="serienBezeichnung" value={currentParams.serienBezeichnung} onChange={handleFilterChange} disabled={currentParams.istSerie !== 'true'}> {/* Disable if not part of series */}
                                        <option value="">Alle Serien</option> {serienBezeichnungOptions.map((s) => <option key={s} value={s}>{s}</option>)}
                                    </select>
                                </div>
                            </fieldset>
                            {/* Dimensions Filters */}
                            <fieldset className="filter-group"> <legend>Maße</legend>
                                <div className="filter-item"> <label htmlFor="gewichtMin">Gewicht von (g):</label> <input type="number" id="gewichtMin" name="gewichtMin" step="0.01" min="0" value={currentParams.gewichtMin} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="gewichtMax">Gewicht bis (g):</label> <input type="number" id="gewichtMax" name="gewichtMax" step="0.01" min="0" value={currentParams.gewichtMax} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="durchmesserMin">Durchm. von (mm):</label> <input type="number" id="durchmesserMin" name="durchmesserMin" step="0.01" min="0" value={currentParams.durchmesserMin} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="durchmesserMax">Durchm. bis (mm):</label> <input type="number" id="durchmesserMax" name="durchmesserMax" step="0.01" min="0" value={currentParams.durchmesserMax} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="laengeMin">Länge von (mm):</label> <input type="number" id="laengeMin" name="laengeMin" step="0.01" min="0" value={currentParams.laengeMin} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="laengeMax">Länge bis (mm):</label> <input type="number" id="laengeMax" name="laengeMax" step="0.01" min="0" value={currentParams.laengeMax} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="breiteMin">Breite von (mm):</label> <input type="number" id="breiteMin" name="breiteMin" step="0.01" min="0" value={currentParams.breiteMin} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="breiteMax">Breite bis (mm):</label> <input type="number" id="breiteMax" name="breiteMax" step="0.01" min="0" value={currentParams.breiteMax} onChange={handleFilterChange} /> </div>
                            </fieldset>
                             {/* Price Filters */}
                             <fieldset className="filter-group"> <legend>Preis</legend>
                                <div className="filter-item"> <label htmlFor="preisProGrammMin">Preis/g von (€):</label> <input type="number" id="preisProGrammMin" name="preisProGrammMin" step="0.01" min="0" value={currentParams.preisProGrammMin} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="preisProGrammMax">Preis/g bis (€):</label> <input type="number" id="preisProGrammMax" name="preisProGrammMax" step="0.01" min="0" value={currentParams.preisProGrammMax} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="kaufPreisMin">Kaufpreis von (€):</label> <input type="number" id="kaufPreisMin" name="kaufPreisMin" step="0.01" min="0" value={currentParams.kaufPreisMin} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="kaufPreisMax">Kaufpreis bis (€):</label> <input type="number" id="kaufPreisMax" name="kaufPreisMax" step="0.01" min="0" value={currentParams.kaufPreisMax} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="verkaufPreisMin">Verkaufspreis von (€):</label> <input type="number" id="verkaufPreisMin" name="verkaufPreisMin" step="0.01" min="0" value={currentParams.verkaufPreisMin} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="verkaufPreisMax">Verkaufspreis bis (€):</label> <input type="number" id="verkaufPreisMax" name="verkaufPreisMax" step="0.01" min="0" value={currentParams.verkaufPreisMax} onChange={handleFilterChange} /> </div>
                            </fieldset>
                            {/* Date Filters */}
                            <fieldset className="filter-group"> <legend>Datum</legend>
                                <div className="filter-item"> <label htmlFor="kaufDatumVon">Kaufdatum von:</label> <input type="date" id="kaufDatumVon" name="kaufDatumVon" value={currentParams.kaufDatumVon} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="kaufDatumBis">Kaufdatum bis:</label> <input type="date" id="kaufDatumBis" name="kaufDatumBis" value={currentParams.kaufDatumBis} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="verkaufDatumVon">Verkaufdatum von:</label> <input type="date" id="verkaufDatumVon" name="verkaufDatumVon" value={currentParams.verkaufDatumVon} onChange={handleFilterChange} /> </div>
                                <div className="filter-item"> <label htmlFor="verkaufDatumBis">Verkaufdatum bis:</label> <input type="date" id="verkaufDatumBis" name="verkaufDatumBis" value={currentParams.verkaufDatumBis} onChange={handleFilterChange} /> </div>
                            </fieldset>
                            {/* Reset Button */}
                            <div className="filter-buttons"> <button type="button" onClick={handleReset} className="btn btn-reset"> Filter zurücksetzen </button> </div>
                        </form>
                    </div>
                )}
            </section>

            <hr className="section-divider" />

            {/* Sorting Controls */}
            <div className="sort-controls">
                <label htmlFor="sortBy">Sortieren nach:</label>
                <select id="sortBy" value={currentParams.sortBy} onChange={handleSortChange}>
                    {/* Options remain the same */}
                     <option value="name">Name</option> <option value="kategorie">Kategorie</option> <option value="status">Status</option> <option value="gewicht">Gewicht</option> <option value="preisProGramm">Preis pro Gramm</option> <option value="kaufPreis">Kaufpreis</option> <option value="verkaufPreis">Verkaufspreis</option> <option value="durchmesser">Durchmesser</option> <option value="laenge">Länge</option> <option value="breite">Breite</option> <option value="kaufDatum">Kaufdatum</option> <option value="verkaufDatum">Verkaufsdatum</option>
                </select>
                <button onClick={toggleSortOrder} className={`sort-order-button ${currentParams.sortOrder === 'asc' ? 'asc' : 'desc'}`} disabled={!currentParams.sortBy} aria-label={`Sortierrichtung umschalten, aktuell ${currentParams.sortOrder === 'asc' ? 'aufsteigend' : 'absteigend'}`} >
                    {currentParams.sortOrder === 'asc' ? (<>Auf <FontAwesomeIcon icon={faSortUp} /></>) : (<>Ab <FontAwesomeIcon icon={faSortDown} /></>)}
                </button>
            </div>

            <hr className="section-divider" />

             {/* Stones List Section - Add ID for easier targeting */}
            <section id="stein-liste-anker" className="stones-list-section">
                <div className="stones-header">
                    {userRole === 'admin' && ( <Link to="/steine/new" className="btn btn-add"> Neuen Stein hinzufügen </Link> )}
                    {/* Info Display */}
                    <div className="stones-count">
                       {isLoading ? ( <p>Lade Steine...</p> ) : error ? null : // Show loading text here or below
                         ( itemsPerPage === 0 ? ( <p>Zeige alle {totalSteine} Steine</p> ) : ( <p> Zeige {aktuelleSteine.length} von {totalSteine} Steinen {gesamtSeiten > 1 && ` (Seite ${currentPage} von ${gesamtSeiten})`} </p> ) )
                       }
                    </div>
                </div>

                {/* Loading / Error / No Results States */}
                 {isLoading && !error && ( <div className="loading-indicator"><p>Lade Steine...</p></div> )}
                 {!isLoading && error && (
                    <div className="error-message"> <p>{error}</p>
                        {!error.includes("anmelden") && !error.includes("Sitzung") && <button onClick={() => fetchSteine(currentParams)} className="btn btn-retry"> Erneut versuchen </button> }
                    </div>
                 )}
                 {!isLoading && !error && aktuelleSteine.length === 0 && ( <p>Keine Steine gefunden, die den Kriterien entsprechen.</p> )}

                 {/* Grid (only render if not loading, no error, and stones exist) */}
                 {!isLoading && !error && aktuelleSteine.length > 0 && (
                    <>
                        <div className="stein-grid">
                            {aktuelleSteine.map((stein) => (
                                <div key={stein._id} className={`stein-card status-${stein.status ? stein.status.replace(/\s+/g, '-').toLowerCase() : 'unbekannt'}`}
                                    onClick={(e) => handleCardClick(e, stein)} role="button" tabIndex={0} onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') handleCardClick(e, stein); }} aria-label={`Details für ${stein.name || 'Unbenannt'} anzeigen`} >
                                    {/* Card content remains the same */}
                                     {stein.bildPfad ? (
                                        <img src={`${process.env.REACT_APP_BASE_URL}/${stein.bildPfad}`} alt={stein.name || 'Steinbild'} loading="lazy" onClick={(e) => handleImageClick(e, stein)}
                                             onError={(e) => { e.target.onerror = null; e.target.src='/placeholder.png'; e.target.alt="Bild nicht ladbar"; e.target.style.objectFit='contain';}} />
                                    ) : ( <div className="image-placeholder" onClick={(e) => e.stopPropagation()} /* Prevent card click on placeholder */ > <span>Kein Bild</span> </div> )}
                                    <div className="stein-details">
                                        <h3 className="stein-name">{stein.name || 'Unbenannt'}</h3>
                                        {/* Details... */}
                                        {stein.kategorie && <p className="stein-property"><span className="property-label">Kat:</span> {stein.kategorie}</p>}
                                        {stein.status && <p className="stein-property"><span className="property-label">Status:</span> {stein.status}</p>}
                                        {stein.gewicht != null && <p className="stein-property"><span className="property-label">Gewicht:</span> {stein.gewicht} g</p>}
                                        {stein.durchmesser != null && <p className="stein-property"><span className="property-label">Durchm.:</span> {stein.durchmesser} mm</p>}
                                        {stein.laenge != null && <p className="stein-property"><span className="property-label">Länge:</span> {stein.laenge} mm</p>}
                                        {stein.breite != null && <p className="stein-property"><span className="property-label">Breite:</span> {stein.breite} mm</p>}
                                        {stein.preisProGramm != null && <p className="stein-property"><span className="property-label">€/g:</span> {stein.preisProGramm}</p>}
                                        {stein.kaufPreis != null && <p className="stein-property"><span className="property-label">Kauf:</span> € {stein.kaufPreis}</p>}
                                        {stein.verkaufPreis != null && <p className="stein-property"><span className="property-label">Verkauf:</span> € {stein.verkaufPreis}</p>}
                                        {stein.serienBezeichnung && <p className="stein-property"><span className="property-label">Serie:</span> {stein.serienBezeichnung}</p>}
                                    </div>
                                    {userRole === 'admin' && (
                                        <div className="button-group">
                                            <button onClick={(e) => handleEdit(e, stein._id)} className="bearbeiten-button" title="Stein bearbeiten"> <FontAwesomeIcon icon={faEdit} /> <span className="button-text">Bearb.</span> </button>
                                            <button onClick={(e) => handleDelete(e, stein._id)} className="loeschen-button" title="Stein löschen"> <FontAwesomeIcon icon={faTrash} /> <span className="button-text">Lösch.</span> </button>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>

                         {/* Pagination Controls */}
                         {gesamtSeiten > 1 && itemsPerPage !== 0 && (
                            <div className="pagination">
                                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1 || isLoading} className="pagination-button"> <FontAwesomeIcon icon={faChevronLeft} /> Zurück </button>
                                <span className="pagination-info" aria-live="polite" aria-atomic="true"> Seite {currentPage} von {gesamtSeiten} </span>
                                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === gesamtSeiten || isLoading} className="pagination-button"> Weiter <FontAwesomeIcon icon={faChevronRight} /> </button>
                            </div>
                        )}

                        {/* Items per Page Selector */}
                        <div className="items-per-page">
                            <label htmlFor="itemsPerPage">Steine pro Seite:</label>
                            <select id="itemsPerPage" value={currentParams.limit} /* Bind to currentParams.limit */ onChange={handleItemsPerPageChange} disabled={isLoading} >
                                <option value="6">6</option> <option value="12">12</option> <option value="24">24</option> <option value="48">48</option> <option value="96">96</option> <option value="0">Alle</option> {/* Use '0' consistently */}
                            </select>
                        </div>
                    </>
                 )}
            </section>

            {/* Modal for Image */}
            {showModal && ( <Modal onClose={() => setShowModal(false)}> <img src={selectedImage} alt="Stein Großansicht" className="modal-image" /> </Modal> )}
        </main>
    );
}

export default SteinListe;