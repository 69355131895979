// components/Login.js
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom'; // Import Navigate
import API from '../services/api';
import '../styles/Login.css';
import '../styles/Form.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faSignInAlt } from '@fortawesome/free-solid-svg-icons';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Check if user is already logged in
    const token = localStorage.getItem('token');
    if (token) {
        // Redirect to homepage if already logged in
        return <Navigate to="/" replace />;
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage(''); // Clear previous messages
        setIsLoading(true);

        API.post('/auth/login', { username, password })
            .then((res) => {
                // Speichere Token und Rolle im localStorage
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('role', res.data.role);
                
                // Debugging: Zeige das Token im Konsolenfenster (nur die ersten 10 Zeichen)
                console.log('Token erhalten:', res.data.token.substring(0, 10) + '...');
                console.log('Rolle:', res.data.role);
                
                // Force reload um sicherzustellen, dass die App komplett neu initialisiert wird
                // Dies ermöglicht einen Neustart ohne stale state
                setTimeout(() => {
                    window.location.href = '/';
                }, 100);
            })
            .catch((err) => {
                console.error("Login Fehler:", err.response?.data || err.message);
                // Provide more specific error feedback if possible
                if (err.response && err.response.status === 401) {
                    setMessage('Login fehlgeschlagen. Benutzername oder Passwort ungültig.');
                } else {
                    setMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
                }
                setIsLoading(false); // Stop loading indicator on error
            });
            // No need for finally here as success causes navigation
    };

    return (
        <main className="login-main">
            <div className="login-container">
                <h2>Login</h2>
                {/* Display message if present */}
                {message && <p className={`message ${message.includes('fehlgeschlagen') || message.includes('Fehler') ? 'error-message' : 'info-message'}`}>{message}</p>}
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="form-group">
                        <label htmlFor="username">
                            <FontAwesomeIcon icon={faUser} className="input-icon" />
                            Benutzername:
                        </label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Benutzername eingeben"
                            autoComplete="username" // Help browser autofill
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            disabled={isLoading} // Disable input while loading
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">
                            <FontAwesomeIcon icon={faLock} className="input-icon" />
                            Passwort:
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Passwort eingeben"
                            autoComplete="current-password" // Help browser autofill
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            disabled={isLoading} // Disable input while loading
                        />
                    </div>
                    <div className="button-group">
                        <button type="submit" className="btn btn-submit" disabled={isLoading}>
                            <FontAwesomeIcon icon={faSignInAlt} /> {isLoading ? 'Anmelden...' : 'Anmelden'}
                        </button>
                    </div>
                </form>
            </div>
        </main>
    );
}


export default Login;